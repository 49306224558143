import { createContext } from 'react'
import { UserContextType } from '../types'

const UserContext = createContext<UserContextType>({
    userInfo: { userId: '', name: '', entityName: '' },
    onLogin: () => {},
    onLogout: () => {}
})

export { UserContext }
