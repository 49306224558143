import { FC, useState, useMemo } from 'react'
import { Box, InputAdornment, IconButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './index.module.scss'

type ILoginFrom = {
    onLogin: (user: string, password: string) => Promise<{ message?: string; success: boolean }>
    onOpenForgotPassword: () => void
}

const LoginForm: FC<ILoginFrom> = props => {
    const { onLogin, onOpenForgotPassword } = props
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const validationSchema = Yup.object({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    })

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema,
        onSubmit: async values => {
            setLoading(true)
            const { message } = await onLogin(values.username, values.password)
            setLoading(false)
            message && formik.setFieldError('password', message)
        }
    })

    const endAdornmentElement = useMemo(() => {
        return (
            <InputAdornment position='end'>
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                        <VisibilityOff className={styles.showPasswordIcon} />
                    ) : (
                        <Visibility className={styles.showPasswordIcon} />
                    )}
                </IconButton>
            </InputAdornment>
        )
    }, [showPassword])

    return (
        <Box className={styles.box}>
            <Box className={styles.logIn}>Log In</Box>

            <TextField
                id='username'
                name='username'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.LoginInput}
                placeholder='Username'
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
                id='password'
                name='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.LoginInput}
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                    endAdornment: endAdornmentElement
                }}
            />
            <LoadingButton
                className={styles.logInButton}
                variant='contained'
                loading={loading}
                onClick={() => {
                    formik.handleSubmit()
                }}>
                Log In
            </LoadingButton>
            <LoadingButton className={styles.forgotPassword} onClick={onOpenForgotPassword}>
                Forgot Password?
            </LoadingButton>
        </Box>
    )
}

export default LoginForm
