import Axios, { AxiosError } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { RequestPost, RequestGet } from '../types'
import { checkResponse } from '../utils'

Axios.defaults.baseURL = window.rtEnv?.API_BASE_URL
Axios.defaults.withCredentials = true

export type RpcParams = [string, string, any[], object, object]

export async function requestPost(path: string, params: RpcParams): Promise<RequestPost> {
    const formatParams = {
        id: uuidv4(),
        method: 'execute',
        params: params
    }
    return Axios(path, {
        method: 'post',
        headers: {},
        data: formatParams
    })
        .then(response => checkResponse(response))
        .catch(handleError)
}

export async function requestGet(path: string, params?: object): Promise<RequestGet> {
    return Axios(path, {
        method: 'get',
        headers: {},
        params
    })
        .then(response => checkResponse(response))
        .catch(handleError)
}

function handleError(error: AxiosError) {
    return {
        data: [],
        success: false,
        errorMessage: error?.message,
        traceId: error.code
    }
}
