import { memo, FC } from 'react'
import { Box, Skeleton } from '@mui/material'
import styles from './index.module.scss'

interface BalancesProps {
    isLoading: boolean
    balances: {
        id: string
        assetName: string
        assetCode: string
        totalBalance: string
        availBalance: string
        earmarkedBalance: string
    }[]
}

const Balances: FC<BalancesProps> = props => {
    const { isLoading = false, balances = [] } = props
    return (
        <Box className={styles.balances}>
            <Box className={styles.balancesTitle}>Balances</Box>
            <Box className={styles.balancesLists}>
                <Box className={styles.balancesHeader}>
                    <Box>Asset</Box>
                    <Box>Total Balance</Box>
                    <Box>Available Balance</Box>
                    <Box>Earmarked Balance</Box>
                </Box>
                {isLoading ? (
                    <Box sx={{ width: '100%' }}>
                        <Skeleton height={50} />
                        <Skeleton height={50} />
                        <Skeleton height={50} />
                    </Box>
                ) : (
                    <Box className={styles.balancesBody}>
                        {balances?.map(
                            balance => {
                                return (
                                    <Box className={styles.balanceRow} key={balance?.assetName}>
                                        <Box>
                                            <Box className={styles.assetName}>
                                                {balance?.assetName}
                                            </Box>
                                            <Box className={styles.assetCode}>
                                                {balance?.assetCode}
                                            </Box>
                                        </Box>
                                        <Box>{balance?.totalBalance}</Box>
                                        <Box>{balance?.availBalance}</Box>
                                        <Box>{balance?.earmarkedBalance}</Box>
                                    </Box>
                                )
                            }
                        )}
                        {balances?.length === 0 && (
                            <Box className={styles.empty}>There are no items to display.</Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default memo(Balances)
