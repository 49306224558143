import { FC, useContext } from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import PageHeader from './PageHeader'
import { UserContext } from '../Context'

const Layout: FC = () => {
    const { userInfo } = useContext(UserContext)
    return (
        <Box sx={{ width: '100vw', height: '100vh' }}>
            <PageHeader />
            <Outlet />
        </Box>
    )
}

export default Layout
