import { createTheme } from '@mui/material/styles'

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#fff'
        }
    }
})

const darkTheme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        },
        overline: {
            textTransform: 'none'
        },
        h5: {
            color: '#fff'
        },
        h6: {
            color: '#fff'
        }
    },
    palette: {
        primary: {
            main: '#2967c1',
            contrastText: '#fff'
        },
        secondary: {
            main: '#2967c1'
        },
        text: {
            primary: '#ffffff'
        },
        background: {
            paper: '#111316',
            default: '#111316'
        },
        mode: 'dark'
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        '-webkit-box-shadow': '0 0 0 100px #16171a inset !important'
                    }
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: 'Roboto'
                }
            }
        }
    }
})

export { lightTheme, darkTheme }
