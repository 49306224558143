import { AxiosResponse } from 'axios'
import { logOut } from '../services/Authentication'
import toFormat from 'toformat'
import DECIMAL from 'decimal.js'

export const Decimal = toFormat(DECIMAL)

export const checkResponse = (response: AxiosResponse) => {
    const handleLogOut = async () => {
        const { success } = await logOut()
        if (success) {
            localStorage.clear()
            window.location.reload()
        }
    }

    const {
        data: { result, error, trace_id }
    } = response
    if (!error) {
        return { data: result, success: true, errorMessage: undefined }
    }
    if (error?.require_logout) {
        handleLogOut()
    }
    return {
        data: [],
        errorMessage: error?.message ?? error,
        traceId: trace_id,
        success: false
    }
}
