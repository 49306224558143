import { memo, useCallback, useEffect, useState, useContext } from 'react'
import { UserContext } from '../../Context'
import { Box } from '@mui/material'
import AccountPanel from './AccountPanel'
import Balances from './Balances'
import {
    getEntityInfo,
    getAccounts,
    getBalances,
    getBalanceFromWallet
} from '../../services/Balances'
import styles from './index.module.scss'
import { Decimal } from '../../utils'

const AccountBalances = () => {
    const { onLogin } = useContext(UserContext)
    const [activeAccountId, setActiveAccountId] = useState('')
    const [accountsLists, setAccountsLists] = useState<
        {
            id: string
            name: string
            number: string
            cold_wallet_support?: string
        }[]
    >([])
    const [balancesIsLoading, setBalancesIsLoading] = useState<boolean>(false)

    const [balancesList, setBalancesList] = useState<
        {
            id: string
            assetName: string
            assetCode: string
            totalBalance: string
            availBalance: string
            earmarkedBalance: string
        }[]
    >([])

    const handleChangeAccountId = useCallback((accountId: string) => {
        if (activeAccountId !== accountId) {
            setActiveAccountId(accountId)
        }
    }, [])

    const coldWalletLinkParams =
        accountsLists.find(account => account?.id === activeAccountId)?.cold_wallet_support ?? ''

    const getColdWalletList = (coldWalletLinkParams: string) => {
        try {
            return JSON.parse(coldWalletLinkParams.replace(/[\n\t]/g, ''))?.wallets
        } catch (e) {
            return []
        }
    }
    const getColdWalletParam = (chain: string, asset?: string) => {
        const chainIdentifier = chain.toLowerCase()

        let api = `${chainIdentifier}scan.io`
        let action = 'balance'
        const contractAddress = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'

        if (chainIdentifier === 'ethereum') {
            api = 'etherscan.io'
            if (asset === 'usdc') {
                action = `tokenbalance&contractaddress=${contractAddress}`
            }
        } else if (chainIdentifier === 'polygon') {
            api = 'polygonscan.com'
        } else if (chainIdentifier === 'binance_smart_chain') {
            api = 'bscscan.com'
        } else if (chainIdentifier === 'avalanche') {
            api = 'snowtrace.io'
        } else if (chainIdentifier === 'bitcoin') {
            api = 'blockcypher.com/v1/btc'
            action = 'coin'
        } else if (chainIdentifier === 'litecoin') {
            api = 'blockcypher.com/v1/ltc'
            action = 'coin'
        }

        return { api, action }
    }

    const getWalletInfo = (wallet: { chain: string; balance: string; asset?: string }) => {
        let assetName = wallet.chain.charAt(0).toUpperCase() + wallet.chain.slice(1)
        let assetCode = wallet.asset ?? ''

        const chainIdentifier = wallet.chain.toLowerCase()

        if (!assetCode) {
            switch (chainIdentifier) {
                case 'ethereum':
                    assetCode = 'ETH'
                    break
                case 'polygon':
                    assetCode = 'MATIC'
                    break
                case 'binance_smart_chain':
                    assetCode = 'BNB'
                    break
                case 'avalanche':
                    assetCode = 'AVAX'
                    break
            }
        }

        if (chainIdentifier.includes('binance')) {
            assetName = 'Binance'
        }

        return { assetName, assetCode: assetCode.toUpperCase() }
    }

    const getColdWalletBalance = useCallback(
        async (item: { chain: string; address: string; asset?: string }) => {
            try {
                const { chain, address, asset } = item
                const { api, action } = getColdWalletParam(chain, asset)
                const balance = await getBalanceFromWallet(api, address, action)
                return {
                    chain,
                    asset,
                    balance: balance?.result || balance?.final_balance || 0
                }
            } catch (error) {
                console.log('error', error)
                return null
            }
        },
        [coldWalletLinkParams]
    )

    useEffect(() => {
        async function getEntity() {
            const { success, data } = await getEntityInfo()
            if (!success) {
                return
            }
            onLogin({ entityName: data?.[0]?.entity_id?.name })
            localStorage.setItem('entityName', data?.[0]?.entity_id?.name)
        }
        async function getAccountList() {
            const { success, data } = await getAccounts()
            if (!success) {
                setAccountsLists([])
                return
            }
            setAccountsLists(data)
            setActiveAccountId(data?.[0]?.id)
        }
        getEntity()
        getAccountList()
    }, [])

    useEffect(() => {
        async function getBalanceList() {
            setBalancesIsLoading(true)
            const coldWalletList = getColdWalletList(coldWalletLinkParams)
            const { success, data } = await getBalances(activeAccountId)
            if (!success) {
                setBalancesList([])
                return
            }
            const formatBalances =
                data?.map((balance, index) => ({
                    id: String(index),
                    assetName: balance?.name,
                    assetCode: balance?.code,
                    totalBalance: Decimal(balance?.amount).toFormat(),
                    availBalance: Decimal(balance?.amount_open).toFormat(),
                    earmarkedBalance: Decimal(balance?.amount_earmark)
                        .neg()
                        .toFormat()
                })) ?? []

            if (coldWalletList.length) {
                const coldWalletBalance = coldWalletList.map(
                    async (item: { chain: string; address: string; asset?: string }) => {
                        const wallet = await getColdWalletBalance(item)

                        if (!wallet) {
                            return null
                        } else {
                            const { assetName, assetCode } = getWalletInfo(wallet)
                            return {
                                id: String(formatBalances.length),
                                assetName: assetName,
                                assetCode: assetCode,
                                totalBalance: Decimal(wallet.balance).toFormat(),
                                availBalance: Decimal(wallet.balance).toFormat(),
                                earmarkedBalance: '0'
                            }
                        }
                    }
                )

                const coldWallets = await Promise.all(coldWalletBalance)
                formatBalances.push(...coldWallets.filter(item => item !== null))
            }
            setBalancesList(formatBalances)
            setBalancesIsLoading(false)
        }
        if (activeAccountId) {
            setBalancesList([])
            getBalanceList()
        }
    }, [activeAccountId])

    return (
        <Box className={styles.balances}>
            <AccountPanel
                accountsLists={accountsLists}
                onChange={handleChangeAccountId}
                activeAccountId={activeAccountId}
            />
            <Balances balances={balancesList} isLoading={balancesIsLoading} />
        </Box>
    )
}

export default memo(AccountBalances)
