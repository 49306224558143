import { FC, useState } from 'react'
import { Box, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './index.module.scss'

type IVerifyEmail = {
    onSubmit: (code: string) => Promise<{ message?: string; success: boolean }>
    onBack?: () => void
}

const VerifyEmail: FC<IVerifyEmail> = props => {
    const { onBack, onSubmit } = props
    const [loading, setLoading] = useState(false)

    const validationSchema = Yup.object({
        code: Yup.string().required('Code is required')
    })

    const formik = useFormik({
        initialValues: {
            code: ''
        },
        validationSchema,
        onSubmit: async values => {
            setLoading(true)
            const { message } = await onSubmit(values.code)
            setLoading(false)
            message && formik.setFieldError('code', message)
        }
    })

    return (
        <Box className={styles.box}>
            <Box className={styles.inputApprovalCode}>Input Approval Code</Box>

            <Box className={styles.inputApprovalText}>
                We sent an approval code to your email in order to protect access to your account.
                Please input it here and click Log in to continue.
            </Box>

            <TextField
                id='code'
                name='code'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.LoginInput}
                placeholder='Approval Code'
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
            />

            <LoadingButton
                className={styles.logInButton}
                variant='contained'
                loading={loading}
                onClick={() => {
                    formik.handleSubmit()
                }}>
                Log In
            </LoadingButton>

            <Button className={styles.back} onClick={onBack}>
                Back
            </Button>
        </Box>
    )
}

export default VerifyEmail
