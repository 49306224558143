import Root from '.././Root'
import Authentication from '../pages/Authentication'
import AccountBalances from '../pages/AccountBalance'

const RootRouter = [
    {
        path: '/accountBalance',
        element: <AccountBalances />,
        name: 'AccountBalance'
    }
]

const NoAuthRouter = [
    {
        path: '/login',
        element: <Authentication />
    }
]

const Router = [
    {
        path: '/',
        element: <Root />,
        children: [...RootRouter]
    },
    ...NoAuthRouter
]

export { RootRouter }

export default Router
