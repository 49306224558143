import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { Box } from '@mui/material'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import styles from './index.module.scss'

const DEFAULT_INTERVAL = 1000
const USE_TIMER_FORMAT = 'ddd MMM DD YYYY HH:mm:ss'

const Timer = React.memo(() => {
    const [formatTime, setFormatTime] = useState<string>('')
    const interval = DEFAULT_INTERVAL

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = moment.tz(new Date(), moment.tz.guess()).format(USE_TIMER_FORMAT)
            setFormatTime(currentTime)
        }, interval)
        return () => clearInterval(intervalId)
    }, [interval])

    return (
        <Box className={styles.timer}>
            <AccessTimeFilledIcon fontSize='small' />
            <Box>{formatTime}</Box>
        </Box>
    )
})

export default Timer
