import { memo, FC } from 'react'
import { Box } from '@mui/material'
import styles from './index.module.scss'
import cn from 'classnames'

interface AccountPanelProps {
    accountsLists: {
        id: string
        name: string
        number: string
    }[]
    activeAccountId: string
    onChange?: (id: string) => void
}

const AccountPanel: FC<AccountPanelProps> = props => {
    const { accountsLists = [], onChange = () => null, activeAccountId } = props
    return (
        <Box className={styles.accountPanel}>
            <Box className={styles.accountPanelTitle}>Accounts</Box>
            <Box className={styles.accountPanelLists}>
                {accountsLists?.map(account => {
                    return (
                        <Box
                            key={account?.id}
                            className={cn({
                                [styles.accountItem]: true,
                                [styles.activeAccountItem]: activeAccountId === account?.id
                            })}
                            onClick={() => onChange(account?.id)}>
                            <Box className={styles.accountNumber}>{account?.number}</Box>
                            <Box className={styles.accountName}>{account?.name}</Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default memo(AccountPanel)
