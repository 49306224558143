import { FC, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './index.module.scss'

type ISendEmail = {
    onBack: () => void
    onSendEmail: (email: string) => Promise<{ message?: string; success: boolean }>
}

const SendEmail: FC<ISendEmail> = props => {
    const { onBack, onSendEmail } = props

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required')
    })

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema,
        onSubmit: values => {
            onSendEmail(values.email)
        }
    })

    return (
        <>
            <Box className={styles.sendEmailText}>Enter your email to reset the password</Box>
            <TextField
                id='email'
                name='email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.emailInput}
                placeholder='Email'
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />

            {/* <Box className={styles.sendEmailToPassword}>
                Go to step 2 - Input the verification code and set your new password
            </Box> */}

            <Button
                className={styles.sendButton}
                variant='contained'
                onClick={() => {
                    formik.handleSubmit()
                }}>
                Send
            </Button>

            <Button className={styles.forgotPassword} onClick={onBack}>
                Back to Login
            </Button>
        </>
    )
}

export default SendEmail
