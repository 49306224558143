import { FC, useCallback, useState } from 'react'
import { Box, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { QRCodeSVG } from 'qrcode.react'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './index.module.scss'

type IVerifyMFA = {
    email: string
    onBack: () => void
    onSubmit: (mfaCode: string) => Promise<{ message?: string; success: boolean }>
    code?: string
}

const VerifyMFA: FC<IVerifyMFA> = props => {
    const { onBack, onSubmit, code, email } = props
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        code: Yup.string().required('Code is required')
    })

    const formatCodeUrl = `otpauth://totp/${email}?secret=${code}&issuer=Hydra%20X`

    const formik = useFormik({
        initialValues: {
            code: ''
        },
        validationSchema,
        onSubmit: values => {
            handleSubmit(values.code)
        }
    })

    const handleSubmit = useCallback(
        async (code: string) => {
            setLoading(true)
            const { message } = await onSubmit(code)
            setLoading(false)
            message && formik.setFieldError('code', message)
        },
        [onSubmit]
    )

    return (
        <Box className={styles.box}>
            <Box className={styles.inputApprovalCode}>MFA Login</Box>

            {code && (
                <>
                    <Box className={styles.inputApprovalText}>
                        Please scan the QR code in Google Authenticator and enter verification code:
                    </Box>

                    <Box className={styles.approvalMFA}>
                        <QRCodeSVG value={formatCodeUrl} size={200} includeMargin={true} />
                    </Box>
                </>
            )}

            <TextField
                id='code'
                name='code'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.LoginInput}
                placeholder='MFA Approval Code'
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
            />

            <LoadingButton
                className={styles.logInButton}
                variant='contained'
                loading={loading}
                onClick={() => {
                    formik.handleSubmit()
                }}>
                Send
            </LoadingButton>

            <Button className={styles.back} onClick={onBack}>
                Back to Login
            </Button>
        </Box>
    )
}

export default VerifyMFA
