import { FC, useContext } from 'react'
import { Box } from '@mui/material'
import { UserDropdownMenu, Timer } from '../../components'
import { UserContext } from '../../Context'
import darkLogo from '../../assets/hxLogoDark.png'
import styles from './index.module.scss'

const PageHeader: FC = () => {
    const { userInfo, onLogout } = useContext(UserContext)

    return (
        <Box className={styles.pageHeader}>
            <Box className={styles.pageHeaderLeft}>
                <img className={styles.logo} src={darkLogo} alt='logo' loading='lazy' />
                <Box className={styles.text}>Hydra X Digital Assets Custody</Box>
            </Box>
            <Box className={styles.pageHeaderRight}>
                <Box>Welcome, {userInfo?.entityName ?? ''}!</Box>
                <Timer />
                <UserDropdownMenu username={userInfo?.name ?? ''} onLogout={onLogout} />
            </Box>
        </Box>
    )
}

export default PageHeader
