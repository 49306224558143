import { FC, useCallback, useEffect, useState } from 'react'
import { Box, Button, InputAdornment, IconButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import styles from './index.module.scss'
import SendEmail from './SendEmail'

type IChangePassword = {
    onBack: () => void
    onSubmit: (info: {
        password: string
        code?: string
        email?: string
    }) => Promise<{ message?: string; success: boolean }>
    isForgotPassword?: boolean
    onSendEmail?: (email: string) => Promise<{ message?: string; success: boolean }>
}

const ChangePassword: FC<IChangePassword> = props => {
    const { onBack, onSubmit, isForgotPassword, onSendEmail } = props

    const [loading, setLoading] = useState(false)
    const [resetLoading, setResetLoading] = useState(false)
    const [passwordStrength, setPasswordStrength] = useState<'Weak' | 'Good'>('Weak')
    const [email, setEmail] = useState('')
    const [forgotPasswordStep, setForgotPasswordStep] = useState<'sendEmail' | 'forgotPassword'>(
        'sendEmail'
    )
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const initialValues = {
        password: '',
        confirmPassword: '',
        ...(isForgotPassword ? { code: '', username: '' } : {})
    }

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least 1 lowercase letter')
            .matches(/\d/, 'Password must contain at least 1 number')
            .matches(/(?=.*?[#?!@$%^&*-.=])/, 'Password must contain at least 1 symbol'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
            .required('Confirm password is required'),
        ...(isForgotPassword
            ? {
                  code: Yup.string().required('Reset code is required'),
                  username: Yup.string().required('Username is required')
              }
            : {})
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setLoading(true)
            const { message } = await onSubmit({
                code: values.code,
                password: values.confirmPassword,
                email: values.username
            })
            setLoading(false)
            message && formik.setFieldError('confirmPassword', message)
        }
    })

    useEffect(() => {
        if (!formik.errors.password && formik.values.password) {
            setPasswordStrength('Good')
        } else {
            setPasswordStrength('Weak')
        }
    }, [formik.errors.password, formik.values.password])

    const handleSendEmail: any = useCallback(
        async (email: string) => {
            if (!onSendEmail || !email) return
            setResetLoading(true)
            setEmail(email)
            const { success } = await onSendEmail(email)
            setResetLoading(false)
            if (success) {
                setForgotPasswordStep('forgotPassword')
            }
        },
        [onSendEmail]
    )

    return (
        <Box className={styles.box}>
            <Box className={styles.newPassword}>{isForgotPassword ? 'Forgot' : 'New'} Password</Box>
            {isForgotPassword && forgotPasswordStep === 'sendEmail' && (
                <SendEmail onBack={onBack} onSendEmail={handleSendEmail} />
            )}

            {(!isForgotPassword || forgotPasswordStep === 'forgotPassword') && (
                <>
                    {isForgotPassword && (
                        <>
                            <TextField
                                id='username'
                                name='username'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={styles.passwordInput}
                                placeholder='Username'
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />

                            <TextField
                                id='code'
                                name='code'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={styles.passwordInput}
                                placeholder='Reset Code'
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                            />
                        </>
                    )}

                    {!isForgotPassword && (
                        <Box className={styles.newPasswordTitle}>Please choose a new password.</Box>
                    )}

                    <TextField
                        id='password'
                        name='password'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={styles.passwordInput}
                        type={showPassword ? 'text' : 'password'}
                        placeholder='New Password'
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? (
                                            <VisibilityOff className={styles.showPasswordIcon} />
                                        ) : (
                                            <Visibility className={styles.showPasswordIcon} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <TextField
                        id='confirmPassword'
                        name='confirmPassword'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={styles.passwordInput}
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder='Confirm New Password'
                        error={
                            formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
                        }
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? (
                                            <VisibilityOff className={styles.showPasswordIcon} />
                                        ) : (
                                            <Visibility className={styles.showPasswordIcon} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <Box className={styles.resetPasswordInfo}>
                        Your password must be at least 8 characters long, containing 1 uppercase
                        letter, 1 lowercase letter, 1 number and 1 symbol.
                    </Box>

                    <Box className={styles.passwordStrength}>
                        Password Strength:{' '}
                        <span
                            className={classNames({
                                [styles.weak]: passwordStrength === 'Weak',
                                [styles.good]: passwordStrength === 'Good'
                            })}
                        >
                            {passwordStrength}
                        </span>
                    </Box>

                    <LoadingButton
                        className={styles.sendButton}
                        variant='contained'
                        loading={loading}
                        onClick={() => {
                            formik.handleSubmit()
                        }}
                    >
                        Change Password
                    </LoadingButton>

                    <Box className={styles.forgotPasswordBottomBox}>
                        <Button className={styles.BottomButton} onClick={onBack}>
                            Back to Login
                        </Button>

                        {isForgotPassword && (
                            <LoadingButton
                                className={styles.BottomButton}
                                loading={resetLoading}
                                onClick={() => handleSendEmail(email)}
                            >
                                Resend Code
                            </LoadingButton>
                        )}
                    </Box>
                </>
            )}
        </Box>
    )
}

export default ChangePassword
