import { requestPost, RpcParams } from '../request'

export async function getEntityInfo() {
    const formatParams: RpcParams = [
        'base.user',
        'search_read_path',
        [[], ['entity_id.name', 'entity_id.number']],
        {},
        {}
    ]
    return requestPost('/json_rpc', formatParams)
}

export async function getAccounts() {
    const formatParams: RpcParams = [
        'tc.account',
        'search_read_path',
        [[], ['number', 'name', 'cold_wallet_support']],
        {},
        {}
    ]
    return requestPost('/json_rpc', formatParams)
}

export async function getBalances(accountId: string): Promise<{
    success: boolean
    data: {
        name: string
        code: string
        amount: string
        amount_earmark: string
        amount_open: string
        asset_id: string
    }[]
}> {
    const formatParams: RpcParams = ['tc.account', 'get_balances', [accountId], {}, {}]
    return requestPost('/json_rpc', formatParams)
}

export async function getBalanceFromWallet(api: string, address: string, action: string) {
    let apiKey = ''
    if (api === 'etherscan.io') {
        apiKey = window.rtEnv?.ETHERSCAN_API_KEY ?? 'BR877J8887T5FJM2KA82UH863IVNE425RZ'
    } else if (api === 'polygonscan.com') {
        apiKey = window.rtEnv?.POLYGONSCAN_API_KEY ?? 'ESM8CEM2IYJGJTTJH58WH1JBPFDCWA5DFQ'
    } else if (api === 'bscscan.com') {
        apiKey = window.rtEnv?.BSCSCAN_API_KEY ?? 'DDFN7Q8FGUXUIT71HBY3RZVZ17SQ9YMXZE'
    } else if (api === 'snowtrace.io') {
        apiKey = window.rtEnv?.SNOWTRACE_API_KEY ?? '9N57HQEY234E8ENAD4MVU6KMN71EEAQHNQ'
    }

    let url = ''
    if (action === 'coin') {
        url = `https://api.${api}/main/addrs/${address}/balance`
    } else {
        url = `https://api.${api}/api?module=account&action=${action}&address=${address}&tag=latest&apikey=${apiKey}`
    }

    const response = await fetch(url)
    return response?.json()
}
