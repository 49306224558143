import { requestPost, RpcParams } from '../request'

export async function login(user: string, password: string) {
    const params = [user, password]
    const formatParams: RpcParams = ['login', 'auth_start', params, {}, {}]
    return requestPost('/json_rpc_pub', formatParams)
}

export async function verifyEmail(value: {
    user: string
    nextChallenge: any
    code: string
    token: string
}) {
    const params = [
        value.user,
        'approve_ip',
        { ip_approve_code: value.code, next_challenge: value.nextChallenge },
        value.token
    ]
    const formatParams: RpcParams = ['login', 'auth_challenge_response', params, {}, {}]
    return requestPost('/json_rpc', formatParams)
}

export async function newPassword(params: { user: string; newPassword: string; token: string }) {
    const args = [
        params.user,
        'new_password',
        {
            new_password: params.newPassword
        },
        params.token
    ]
    const formatParams: RpcParams = ['login', 'auth_challenge_response', args, {}, {}]
    return requestPost('/json_rpc', formatParams)
}

export async function sendEmailCode(user: string) {
    const params = [{ email: user }]
    const formatParams: RpcParams = ['login', 'request_reset_password', params, {}, {}]
    return requestPost('/json_rpc', formatParams)
}

export async function forgetPassword(params: { user: string; newPassword: string; code: string }) {
    const args = [params.user, params.code, params.newPassword]
    const formatParams: RpcParams = ['login', 'reset_password', args, {}, {}]
    return requestPost('/json_rpc', formatParams)
}

export async function verifyMFALogin(params: {
    user: string
    code: string
    token: string
    isFirst: boolean
}) {
    const args = [
        params.user,
        params.isFirst ? 'mfa_setup' : 'soft_mfa',
        { mfa_code: params.code },
        params.token
    ]
    const formatParams: RpcParams = ['login', 'auth_challenge_response', args, {}, {}]
    return requestPost('/json_rpc', formatParams)
}

export async function logOut() {
    const formatParams: RpcParams = [
        'login',
        'process_logout_clear_all_cookies_in_specified_domain',
        [],
        {},
        {}
    ]
    return requestPost('/json_rpc_pub', formatParams)
}
