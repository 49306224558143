import { memo, FC, useEffect, useReducer, useCallback, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { darkTheme, lightTheme } from '../utils/theme'
import { ThemeProvider } from '@mui/material/styles'

import { UserContext, ThemeContext } from '../Context'
import { UserInfo, Action } from '../types/context'
import Layout from '../Layout'
import { logOut } from '../services/Authentication'

const userReducer = (state: UserInfo, action: Action) => {
    switch (action.type) {
        case 'SET_USER_INFO':
            return { ...state, ...action.payload }
        case 'CLEAR_USER_INFO':
            return {
                userId: '',
                name: ''
            }
        default:
            return state
    }
}

const Root: FC = () => {
    const [userInfo, dispatch] = useReducer(userReducer, {
        userId: localStorage.getItem('userId') ?? '',
        name: localStorage.getItem('name') ?? '',
        entityName: localStorage.getItem('entityName') ?? ''
    })
    const [theme, setTheme] = useState<'dark' | 'light'>('dark')
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (userInfo.userId) {
            if (location.pathname === '/') {
                navigate('/accountBalance')
            }
        } else {
            navigate('/login')
        }
    }, [userInfo.userId, location.pathname])

    const changeTheme = useCallback((value: 'dark' | 'light') => {
        setTheme(value)
    }, [])

    const onLogin = useCallback((value: any) => {
        dispatch({
            type: 'SET_USER_INFO',
            payload: value
        })
    }, [])

    const onLogout = useCallback(async () => {
        const { success } = await logOut()
        if (success) {
            dispatch({
                type: 'CLEAR_USER_INFO',
                payload: {}
            })
            localStorage.clear()
        }
    }, [])

    const userValue = useMemo(() => {
        return { onLogin, onLogout, userInfo }
    }, [onLogin, onLogout, userInfo])

    const themeValue = useMemo(() => {
        return theme === 'dark' ? darkTheme : lightTheme
    }, [theme])

    return (
        <ThemeContext.Provider value={{ theme, changeTheme }}>
            <ThemeProvider theme={themeValue}>
                <CssBaseline />
                <UserContext.Provider value={userValue}>
                    <Layout />
                </UserContext.Provider>
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

export default memo(Root)
