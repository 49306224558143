import { FC, useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { darkTheme } from '../../utils/theme'
import LoginForm from './LoginForm'
import VerifyEmail from './VerifyEmail'
import ChangePassword from './ChangePassword'
import VerifyMFA from './VerifyMFA'
import {
    login,
    verifyEmail,
    newPassword,
    sendEmailCode,
    forgetPassword,
    verifyMFALogin
} from '../../services/Authentication'

type Step = 'loginForm' | 'verifyEmail' | 'changePassword' | 'verifyMFA'

type Challenge = 'approve_ip' | 'new_password' | 'mfa_setup' | 'soft_mfa'

const CHALLENGE_STEP = {
    approve_ip: 'verifyEmail',
    new_password: 'changePassword',
    mfa_setup: 'verifyMFA',
    soft_mfa: 'verifyMFA'
}

const Authentication: FC = () => {
    const navigate = useNavigate()
    const [step, setStep] = useState<Step>('loginForm')
    const [username, setUsername] = useState('')
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    const [verifyParams, setVerifyParams] = useState<{
        nextChallenge?: unknown
        token: string
        mfaCode?: string
    }>()

    const handleLoginStep = (data: { challenge: Challenge; [key: string]: string }) => {
        const { challenge } = data
        if (data?.user_id) {
            localStorage.setItem('userId', data?.user_id)
            navigate('/')
        }
        setVerifyParams({
            nextChallenge: data?.next_challenge,
            token: data?.session,
            mfaCode: data?.secret_code
        })
        challenge && setStep(CHALLENGE_STEP[challenge] as Step)
    }

    const openForgotPassword = useCallback(() => {
        setStep('changePassword')
        setIsForgotPassword(true)
    }, [])

    const handleBackLoginFrom = useCallback(() => {
        setStep('loginForm')
        setIsForgotPassword(false)
        setVerifyParams(undefined)
    }, [setStep, setIsForgotPassword, setVerifyParams])

    const handleLogin = useCallback(
        async (user: string, password: string) => {
            const { data, success, errorMessage } = await login(user, password)
            setUsername(user)
            if (success) {
                localStorage.setItem('name', user)
                handleLoginStep(data)
            }
            return { success, message: errorMessage }
        },
        [navigate]
    )

    const handleVerifyEmailCode: any = useCallback(
        async (code: string) => {
            const { data, success, errorMessage } = await verifyEmail({
                user: username,
                nextChallenge: verifyParams?.nextChallenge,
                code,
                token: verifyParams?.token as string
            })

            if (success) {
                handleLoginStep(data)
            }

            return { success, message: errorMessage }
        },
        [verifyParams, username]
    )

    const handleChangePasswordSubmit = useCallback(
        async (info: { password: string; code?: string | undefined; email?: string }) => {
            if (isForgotPassword) {
                const { success, errorMessage } = await forgetPassword({
                    user: info.email as string,
                    code: info.code as string,
                    newPassword: info.password
                })
                success && setStep('loginForm')
                return { success, message: errorMessage }
            }

            const { data, success, errorMessage } = await newPassword({
                user: username,
                token: verifyParams?.token as string,
                newPassword: info.password
            })

            if (success) {
                handleLoginStep(data)
            }

            return { success, message: errorMessage }
        },
        [verifyParams, isForgotPassword, username]
    )

    const handleResendEmail: any = useCallback(async (user: string) => {
        const { success, errorMessage } = await sendEmailCode(user)
        return { success, message: errorMessage }
    }, [])

    const handleMFASubmit = useCallback(
        async (code: string) => {
            const { success, errorMessage, data } = await verifyMFALogin({
                user: username,
                code,
                token: verifyParams?.token as string,
                isFirst: verifyParams?.mfaCode ? true : false
            })
            if (success) {
                handleLoginStep(data)
            }
            return { success, message: errorMessage }
        },
        [verifyParams, username]
    )

    const authStep = useMemo(() => {
        switch (step) {
            case 'verifyEmail':
                return <VerifyEmail onBack={handleBackLoginFrom} onSubmit={handleVerifyEmailCode} />
            case 'changePassword':
                return (
                    <ChangePassword
                        onBack={handleBackLoginFrom}
                        onSubmit={handleChangePasswordSubmit}
                        isForgotPassword={isForgotPassword}
                        onSendEmail={handleResendEmail}
                    />
                )
            case 'verifyMFA':
                return (
                    <VerifyMFA
                        onBack={handleBackLoginFrom}
                        email={username}
                        code={verifyParams?.mfaCode}
                        onSubmit={handleMFASubmit}
                    />
                )
            default:
                return <LoginForm onLogin={handleLogin} onOpenForgotPassword={openForgotPassword} />
        }
    }, [step, verifyParams, username])

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            {authStep}
        </ThemeProvider>
    )
}

export default Authentication
